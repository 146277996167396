import React from "react";

interface ProjectProps {
    name: string;
    description?: string;
    time?: string;
    role?: string;
    technologies?: string;
    projects: React.ReactNode;
}

const Projects = ({ role, technologies, name, description, time, projects }: ProjectProps) => {
    return (
        <div className="project__main">
            <h2 className="project__title">{name}</h2>

            <div className="project__content">
                {(time || technologies || role) && (
                    <div className="project__subtitleGroup">
                        {time && (
                            <h3 className="project__subtitle">
                                <span className="project__subtitleLabel">Time:</span> {time}
                            </h3>
                        )}
                        {technologies && (
                            <h3 className="project__subtitle">
                                <span className="project__subtitleLabel">Technologies:</span> {technologies}
                            </h3>
                        )}
                        {role && (
                            <h3 className="project__subtitle">
                                <span className="project__subtitleLabel">Role:</span> {role}
                            </h3>
                        )}
                    </div>
                )}

                {description && <p className="project__description">{description}</p>}

                <div className="subprojects__main">{projects}</div>
            </div>
        </div>
    );
};

export default Projects;
