import React from "react";
import Layout from "../components/Layout";
import ButtonLink from "../components/ButtonLink";
import { Link } from "gatsby";
import Awards from "../components/about/Awards";
import Education from "../components/about/Education";
import Seo from "../components/Seo";

const About = () => {
    return (
        <Layout>
            <Seo title="About me - Arek Wtykło"/>

            <h1>About me</h1>

            <div className="grid__main">
                <div className="grid__left -wide text__justify">
                    <p className="text__lead">Hi! I'm Arek - software engineer. Born and raised in Lublin, Poland.</p>

                    <br />

                    <p>
                        After finishing highschool, I started working with my brother Marcin who just started an IT
                        company (
                        <a className="text__link" href="http://www.arteneo.pl" rel="nofollow" target="_blank">
                            Arteneo
                        </a>
                        ). We focused on web technologies and started building websites using Symfony (PHP).
                    </p>

                    <p>
                        As soon as I started studying computer science at Warsaw University of Technology I moved to
                        Warsaw. I shared time between studying and working. After finishing bachelor's degree (2013) I
                        decided to move back to Lublin.
                    </p>

                    <p>
                        Over the years I have worked on over 120 projects - you can read more about recent ones on{" "}
                        <Link to="/projects" className="text__link">
                            projects page
                        </Link>
                        . I also started sharing knowledge as a trainer at InfoShare Academy (since 2017).
                    </p>

                    <p>
                        Aside from development I enjoy coffee and traveling. Recently visited Albania - amazing people
                        and views. Currently planning a trip to Tanzania.
                    </p>

                    <br />

                    <h2>Areas of expertise</h2>

                    <ul className="text__ul">
                        <li>
                            <strong>React.js, TypeScript, MUI</strong>, Javascript, HTML, CSS, Sass, LESS
                        </li>
                        <li>
                            <strong>Symfony, PHP</strong>, Doctrine, SQL
                        </li>
                        <li>
                            <strong>Docker</strong>
                        </li>
                        <li>Git, GitHub, GitLab, Bitbucket, Sphinx, LaTeX</li>
                        <li>Virtual Private Networking, Certificates, Firewall, PKI, Routing, NAT, OPNsense</li>
                        <li>
                            REST API, integration with third-party solutions (Microsoft, Google, Bosch Data Collection
                            Service etc.)
                        </li>
                        <li>Professional working proficiency in english</li>
                    </ul>

                    <br />

                    <h2>InfoShare Academy trainer</h2>

                    <p>
                        I tought over 120 developers on both online and stationary courses. Developed lesson plans for:
                        TypeScript, Processing data structures, OOP and prototypes, Bootstrap, RWD.
                    </p>
                    <p>
                        I also supported students during project workshops and retrospectives. Took part in teachers
                        team that improved and refined upcoming courses. Achieved trainer evaluation score 4,7 out of 5.
                    </p>

                    <br />

                    <Awards />

                    <Education />

                    <br />

                    <p className="buttonLink__text">
                        That is all about me. You can follow up to my projects or how to reach me.
                    </p>

                    <ButtonLink {...{ title: "Projects", to: "/projects" }} />
                    <ButtonLink {...{ title: "Contact", to: "/contact" }} />
                </div>
            </div>
        </Layout>
    );
};

export default About;
