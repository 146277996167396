import React from "react";
import Subproject from "../Subproject";

const BachelorsDegree = () => {
    return (
        <Subproject
            {...{
                name: "Bachelor’s degree in computer science",
                time: "2013",
                description: "Warsaw University of Technology.",
            }}
        />
    );
};

export default BachelorsDegree;
