import React from "react";
import Projects from "../../components/Projects";
import BachelorsDegree from "./BachelorsDegree";
import DockerMaster from "./DockerMaster";

const Education = () => {
    return (
        <Projects
            {...{
                name: "Education",
                projects: (
                    <>
                        <DockerMaster />
                        <BachelorsDegree />
                    </>
                ),
            }}
        />
    );
};

export default Education;
