import React from "react";
import Projects from "../../components/Projects";
import DistrictGame from "./DistrictGame";
import Zaklikaj from "./Zaklikaj";

const Awards = () => {
    return (
        <Projects
            {...{
                name: "Awards",
                projects: (
                    <>
                        <Zaklikaj />
                        <DistrictGame />
                    </>
                ),
            }}
        />
    );
};

export default Awards;
