import React from "react";
import Subproject from "../Subproject";

const Zaklikaj = () => {
    return (
        <Subproject
            {...{
                name: "Zaklikaj.com as best start-up of lower Silesia province",
                time: "2016",
                link: "https://gazetawroclawska.pl/finisz-plebiscytu-na-najlepszy-startup-wesprzyj-swego-faworyta/ar/10265448",
                linkName: "gazetawroclawska.pl",
                description:
                    "Zaklikaj has won the competition for the best startup of lower Silesia province conducted by The Wrocław newspaper.",
            }}
        />
    );
};

export default Zaklikaj;
