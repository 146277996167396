import React from "react";
import Subproject from "../Subproject";

const DockerMaster = () => {
    return (
        <Subproject
            {...{
                name: "Docker Master course",
                time: "2021",
                description:
                    "Finished course by Łukasz Lach senior architect in GOG.com (including DevOps, QA, Back-end and Front-end paths).",
            }}
        />
    );
};

export default DockerMaster;
