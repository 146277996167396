import React from "react";
import Subproject from "../Subproject";

const DistrictGame = () => {
    return (
        <Subproject
            {...{
                name: "IPiSS award for the District Game",
                time: "2015",
                link: "https://www.ipiss.com.pl/?konkurs-lider=zzl-w-firmach-xvi-edycji",
                linkName: "ipiss.com.pl",
                description:
                    "Human resources management award issued by Institute of Labor and Social Affairs (IPiSS) for the District Game created for Orange telecommunication company.",
            }}
        />
    );
};

export default DistrictGame;
